import * as types from "../actions/types";
import { DateTime } from "luxon";

const initialState = {
  loading: false,
  refreshing: false,
  license: "",
  menuData: "",
  selected_language: "",
  languages: "",
  menuFormSubmitted: false,
  persistedAt: "",
  isOpen: true,
};

const MenuReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.LOADING:
      return {
        ...state,
        loading: true,
      };
    case types.SAVE_LICENSE:
      return {
        ...state,
        loading: false,
        license: action.license,
      };
    case types.SAVE_LANGUAGES:
      return {
        ...state,
        selected_language: action.selected_language,
        languages: action.languages,
      };
    case types.CHANGE_LANGUAGE:
      return {
        ...state,
        selected_language: action.selected_language,
      };
    case types.SAVE_MENU_INFO:
      return {
        ...state,
        loading: false,
        refreshing: false,
        menuData: action.menuData,
        persistedAt: DateTime.now(),
      };
    case types.REFRESHING_MENU:
      return {
        ...state,
        refreshing: true,
      };
    case types.GET_ERRORS:
      return {
        ...state,
        loading: false,
        refreshing: false,
      };
    case types.MENU_FORM_SUBMITTED:
      return {
        ...state,
        menuFormSubmitted: true,
      };
    case types.MENU_IS_OPEN:
      return {
        ...state,
        isOpen: true,
      };
    case types.MENU_IS_CLOSED:
      return {
        ...state,
        isOpen: false,
      };
    default:
      return state;
  }
};

export default MenuReducer;
