const QuantityStyle = {
  container: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "center",
    alignItems: "center",
    paddingTop: 35,
    paddingBottom: 35,
  },
  cartContainer: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "center",
    alignItems: "center",
    paddingBottom: 15,
  },
  title: {
    paddingRight: 10,
    color: "#AAA",
  },
  button: {
    minWidth: 32,
  },
  cartButton: {
    minWidth: 20,
    height: 20,
    padding: 0,
  },
  buttons: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "center",
    alignItems: "center",
  },
  qty: {
    paddingLeft: 10,
    paddingRight: 10,
    minWidth: 40,
    textAlign: "center",
  },
  cartQty: {
    paddingLeft: 8,
    paddingRight: 8,
    textAlign: "center",
  },
};

export default QuantityStyle;
