import React from "react";
import { useSelector } from "react-redux";
import SwiperCore, { Navigation, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { css } from "@emotion/css";
import { makeStyles } from "@material-ui/core/styles";
import CategoryStyle from "../../../views/CategoryPage/CategoryStyle";

const useStyles = makeStyles(CategoryStyle);
// install Swiper components
SwiperCore.use([Navigation, Pagination]);

export default function RenderPromotions(props) {
  const { currentCategory } = props;
  const classes = useStyles();
  const menuInfo = useSelector((state) => state.menuInfo);
  const { promotions, theme_data } = menuInfo.menuData;

  return (
    <div className={classes.promotions}>
      <Swiper
        simulateTouch={false}
        slidesPerView={1}
        navigation={currentCategory.promotions.length > 1}
        pagination={{ clickable: true }}
        className={css`
          > .swiper-button-prev,
          > .swiper-button-next {
            color: ${theme_data?.product_screen_arrow_color};
          }
        `}
      >
        {currentCategory.promotions.map((promo, index) => (
          <SwiperSlide key={index}>
            <img
              src={
                promotions.find((promotion) => promotion.id === promo).image
                  .product
              }
              srcSet={`${
                promotions.find((promotion) => promotion.id === promo).image
                  .product
              } 1x,
                ${
                  promotions.find((promotion) => promotion.id === promo).image
                    .retina
                } 2x`}
              alt={promotions.find((promotion) => promotion.id === promo).title}
              className={classes.promoImage}
            />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
}
