import {
  container,
  grayColor,
  main,
  mainRaised,
  title,
  whiteColor,
} from "assets/jss/material-kit-pro-react.js";
import footerStyle from "assets/jss/material-kit-pro-react/views/componentsSections/footerStyle.js";

const WelcomeStyle = {
  ...footerStyle,
  main: {
    ...main,
    /*overflow: "hidden"*/
  },
  mainContainer: {
    position: "relative",
    width: "inherit",
    maxWidth: "inherit",
  },
  mainRaised,
  parallax: {
    height: "90vh",
    overflow: "hidden",
  },
  container: {
    ...container,
    zIndex: 1,
  },
  title: {
    ...title,
    color: whiteColor,
    fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
  },
  brand: {
    color: whiteColor,
    textAlign: "center",
    "& h1": {
      fontSize: "4.2rem",
      fontWeight: "600",
      display: "inline-block",
      position: "relative",
    },
  },
  footerBrand: {
    padding: "15px 15px",
    fontSize: "18px",
    lineHeight: "50px",
    color: grayColor[1],
    textDecoration: "none",
    fontWeight: 700,
    fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
  },
  footerLink: {
    color: grayColor[1],
  },
  socialLinks: {
    marginBottom: 0,
    padding: 0,
    listStyle: "none",
    "& li": {
      display: "inline-block",
    },
    "& a": {
      display: "block",
    },
  },
};

export default WelcomeStyle;
