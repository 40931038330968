import * as types from "./types";
import axios from "axios";
import { APP_URL } from "../../constants/Api";

export const setMenuFormSubmitted = () => {
  return {
    type: types.MENU_FORM_SUBMITTED,
  };
};

export const saveLicense = (license) => {
  return {
    type: types.SAVE_LICENSE,
    license,
  };
};

export function customDomainLicense(domain) {
  return async (dispatch) => {
    dispatch({
      type: types.LOADING,
    });
    axios
      .get(`${APP_URL}/en/menus/custom-domain/${domain}/`, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        dispatch({
          type: types.SAVE_LICENSE,
          license: response.data.license,
        });
        dispatch({
          type: types.CUSTOM_DOMAIN,
        });
      })
      .catch((error) => {
        // Error
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          // console.log(error.response.data);
          // console.log(error.response.status);
          // console.log(error.response.headers);
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the
          // browser and an instance of
          // http.ClientRequest in node.js
          //console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          //console.log("Error", error.message);
        }
        //console.log(error.config);
        dispatch({
          type: types.SAVE_LICENSE,
          license: "none",
        });
      });
  };
}

export function changeLanguage(number, lang = "en") {
  return async (dispatch) => {
    dispatch({
      type: types.CHANGE_LANGUAGE,
      selected_language: lang,
    });
    dispatch(getMenuInfo(number, lang));
  };
}

export function getMenuLanguages(number) {
  return async (dispatch) => {
    dispatch({
      type: types.LOADING,
    });
    axios
      .get(`${APP_URL}/en/api/menu_language/${number}/`, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        dispatch({
          type: types.SAVE_LANGUAGES,
          selected_language: response.data.default_language,
          languages: response.data.languages,
        });
        dispatch(getMenuInfo(number, response.data.default_language, false));
      })
      .catch((error) => {
        // Error
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          console.log(error.response.data);
          console.log(error.response.status);
          //console.log(error.response.headers);
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the
          // browser and an instance of
          // http.ClientRequest in node.js
          //console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          //console.log("Error", error.message);
        }
        //console.log(error.config);
        dispatch({
          type: types.GET_ERRORS,
        });
      });
  };
}

// Fetch menu data function
async function fetchMenuData(number, lang, dispatch) {
  axios
    .get(`${APP_URL}/${lang}/api/menu/${number}/`, {
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      if (response.data.license_status === "ACTIVE") {
        dispatch({
          type: types.SAVE_MENU_INFO,
          menuData: response.data,
        });
        dispatch({
          type: types.SAVE_LICENSE,
          license: response.data.license,
        });
      } else {
        dispatch({
          type: types.GET_ERRORS,
        });
      }
    })
    .catch((error) => {
      // Error
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.log(error.response.data);
        console.log(error.response.status);
        //console.log(error.response.headers);
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the
        // browser and an instance of
        // http.ClientRequest in node.js
        //console.log(error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        //console.log("Error", error.message);
      }
      //console.log(error.config);
      dispatch({
        type: types.GET_ERRORS,
      });
    });
}

export function getMenuInfo(number, lang = "en", refresh = false) {
  return async (dispatch) => {
    refresh
      ? dispatch({
          type: types.REFRESHING_MENU,
        })
      : dispatch({
          type: types.LOADING,
        });
    await fetchMenuData(number, lang, dispatch);
  };
}

export function refreshMenuInfo(number, lang = "en") {
  return async (dispatch) => {
    await fetchMenuData(number, lang, dispatch);
  };
}

export const setMenuOpen = () => {
  return {
    type: types.MENU_IS_OPEN,
  };
};

export const setMenuClosed = () => {
  return {
    type: types.MENU_IS_CLOSED,
  };
};
