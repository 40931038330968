import React, { useState } from "react";
import { currency_symbol } from "../../../constants/utils";
import { useSelector } from "react-redux";

export default function VariationPricing(props) {
  const { product, price, discountedPrice, view } = props;
  const { product_variations } = product;
  const menuInfo = useSelector((state) => state.menuInfo);
  const {
    variations,
    theme_data,
    display_prices_with_tax,
    taxes,
    price_decimal_places,
    currency,
    display_currency,
  } = menuInfo.menuData;

  const [filteredProductVariations] = useState(
    view === "product"
      ? product_variations.filter(
          (variation) => variation.display_price_product
        )
      : product_variations.filter((variation) => variation.display_price)
  );

  const [productPrice] = useState(price);
  const [productDiscountedPrice] = useState(discountedPrice);

  const getTaxes = (price) => {
    let taxAmount = 0;
    if (taxes.length > 0 && display_prices_with_tax) {
      for (let tax of taxes) {
        if (tax.type === "percentage") {
          taxAmount += (parseFloat(price) * parseFloat(tax.rate)) / 100;
        }
      }
    }
    return taxAmount;
  };

  const getVariationPrice = (variationPrice) => {
    let taxes;
    const vPrice = parseFloat(variationPrice);
    const vDiscountedPrice =
      parseFloat(vPrice) - (parseFloat(vPrice) * product.discount) / 100;
    if (product.discount > 0) {
      taxes = getTaxes(vDiscountedPrice);
      return (
        parseFloat(vDiscountedPrice) +
        taxes +
        parseFloat(productDiscountedPrice)
      ).toFixed(price_decimal_places);
    } else {
      taxes = getTaxes(vPrice);
      return (parseFloat(vPrice) + taxes + parseFloat(productPrice)).toFixed(
        price_decimal_places
      );
    }
  };

  const priceStyle = {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: view === 4 ? "center" : "space-between",
    flexDirection: view === 4 ? "column" : "row",
  };

  return (
    <div>
      <div style={priceStyle}>
        {filteredProductVariations.map((v, i) => (
          <div
            key={i}
            style={{
              display: "flex",
              flexDirection: view === 4 ? "row" : "column",
              alignItems: view === 4 ? "normal" : "center",
              marginBottom: view === "product" ? "10px" : "10px",
            }}
          >
            <div>
              {display_currency ? currency_symbol(currency) : null}
              {getVariationPrice(v.price)}
            </div>
            <div
              style={{
                fontSize:
                  view === "product"
                    ? Number(
                        theme_data?.product_screen_product_price_font_size
                      ) - 2
                    : view === 4
                    ? Number(
                        theme_data?.category_screen_product_price_font_size
                      )
                    : Number(
                        theme_data?.category_screen_product_price_font_size
                      ) - 2,
                margin: view === 4 ? "0 10px" : "0px",
                textTransform: "uppercase",
              }}
            >
              {variations.map((variation) =>
                variation.id === v.variation
                  ? variation.values.find((vv) => vv.id === v.variation_value)
                      .value
                  : null
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
